@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@500&family=Gwendolyn:wght@400;700&family=Julee&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
    overflow-x: hidden !important;
}
video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.99;
    z-index: -10;
}
body {
    background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px, linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px, linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px, linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px, linear-gradient(90deg, #1b1b1b 10px, transparent 10px), linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
    background-color: #131313;
    background-size: 20px 20px;
    font-family: 'Alegreya Sans SC', sans-serif;
    padding: 0px 0px;
    margin: 0px 0px;
    color: #ff8605;
    text-shadow: 0 -1px 0 #FFF, 0 -2px 2px #ff0, 0 -1px 1px #ff8000, 0 -5px 20px #F00;
    scrollbar-width: none;
    -ms-overflow-style: none;
    z-index: -5;
}
/*splash landing page*/
.splashPage {
    height: 100vh;
    width: 100vw;
    margin: 0;
}
.splashPageImg {
    background-image: linear-gradient(to top,rgba(255,96,0,0.25), rgba(239,239,239,0)), url("../public/spudHunterBG.png");
    background-size: 100% 100%;
    background-position: center;
    object-fit: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    margin: 0;
    position: absolute;
    display: flex;
    justify-content: center;
}
/*guides landing page*/
.guidesPage {
    height: 100vh;
    width: 100vw;
    margin: 0;
}
.guidesTitle{
    width: 100%;
    z-index: 99;
}
.guidesImg {
    background-image: linear-gradient(to top,rgba(255,96,0,0.25), rgba(239,239,239,0)), url("../public/wizardGuide.png");
    background-size: 100% 100%;
    background-position: center;
    object-fit: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    margin: 0;
    position: absolute;
    display: flex;
    justify-content: center;
}
/*builds landing page*/
.buildsPage {
    height: 100vh;
    width: 100vw;
    margin: 0;
}

.buildsTitle {
    width: 100%;
    z-index: 99;
}

.buildsImg {
    background-image: linear-gradient(to top,rgba(255,96,0,0.25), rgba(239,239,239,0)), url("../public/buildsPage.png");
    background-size: 100% 100%;
    background-position: center;
    object-fit: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    margin: 0;
    position: absolute;
    display: flex;
    justify-content: center;
}
/*builds landing page*/
.socialsPage {
    height: 100vh;
    width: 100vw;
    margin: 0;
}

.socialsTitle {
    width: 100%;
    z-index: 99;
}

.socialsImg {
    background-image: linear-gradient(to top,rgba(255,96,0,0.25), rgba(239,239,239,0)), url("../public/socialsPage.png");
    background-size: 100% 100%;
    background-position: center;
    object-fit: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    margin: 0;
    position: absolute;
    display: flex;
    justify-content: center;
}
/* begin home */
nav {
    background: none;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.5rem;
    box-shadow: 2px 3px 2px #ff8605;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
    margin-bottom: 50px;
    height: 80px;
    scroll-margin: unset;
    overflow: hidden;
    -ms-overflow-style: none;
}
.nav::-webkit-scrollbar {
    width: 0 !important;
}
nav:hover {
    background: linear-gradient(-45deg,#290d0a,#240f07,#221710,#220809);
}
.hamburger {
    display: none;
}
.logo {
    font-size: 2rem;
    font-weight: 500;
    text-decoration: none;
}

.homeUL {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ff8605;
    text-shadow: 0 -1px 0 #FFF, 0 -2px 2px #ff0, 0 -1px 1px #ff8000, 0 -5px 20px #F00;
}

.nav-item {
    margin-left: 2rem;
    color: #ff8605;
}
.nav-item:hover {
    margin-left: 2rem;
    color: #000;
    
    text-shadow: 0 -1px 0 #FFF, 0 -2px 2px #ff0, 0 -1px 1px #ff8000, 0 -5px 20px #F00;
}

.nav-link {
    font-weight: 600;
    color: #ff8605;
    text-decoration: none;
}
.homeHeader {
    color: #ff8605;
    text-shadow: 0 -1px 0 #FFF, 0 -2px 2px #ff0, 0 -1px 1px #ff8000, 0 -5px 20px #F00;
}
.jab , .tv{
    align-items: center;
    display: flex;
}
.fist{
    align-items: center;
    display: flex;
    top: -15px;
}
@media(max-width: 1800px){
    .classes {
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 25px;
        grid-row-gap: 25px;
    }
}
@media(max-width: 1200px) {
    .classes {
        display: grid;
        grid-template-columns: 2fr;
        grid-template-rows: repeat(6, 1fr);
        grid-column-gap: 25px;
        grid-row-gap: 25px;
    }
    .classContainer {
        font-size: 35px;
    }
}
@media(max-width: 660px){
    .homeUL{
        display:none;
    }
    .classFighter{
        position: relative;
        left: -100px;
    }
    .classCleric {
        position: relative;
        left: -100px;
    }
    .classBarbarian {
        position: relative;
        left: -100px;
    }
    .classRanger {
        position: relative;
        left: -100px;
    }
    .classWizard {
        position: relative;
        left: -100px;
    }
    .classRogue {
        position: relative;
        left: -100px;
    }

}
@media(max-width: 380px) {
    .homeHeader {
        font-size: 25px;
    }
    .classContainer {
        font-size: 25px;
    }
}
/* end of home */
.hidden {
  display: none !important;
}

.message {
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 600;
  margin-top: 24px;
}

.disputed {
  color: #ef4444;
  font-weight: 600;
  margin-right: 10px;
}

/* MAIN LAYOUT */
#root {
  /* max-width: 2200px; */
  margin: 0 auto;
}
h2{
    margin: 100px;
    z-index: 50;
    position: relative;
}
.header {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 48px;
  height: calc(100vh - 48px - 68px - 40px);
}
.pvptier{
  display: grid;
  margin-top: 50px;
  margin-bottom: 50px;
}
@media (max-width: 900px) {
  .main {
    grid-template-columns: 1fr;
    /* Fixed in a video text overlay, to fix scrolling issue on small screens */
    height: auto;
  }
}

section {
  overflow: hidden;
  padding-bottom: 40px;
}

/* ELEMENTS */

.logo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.logo img {
  width: 68px;
  height: 68px;
}

h1 {
  font-size: 42px;
  text-transform: uppercase;
  font-family: 'Alegreya Sans SC', sans-serif;
  line-height: 1;
  margin-top: 6px;
  margin-right: 16px;
  display: flex;
}

.category {
  margin-bottom: 8px;
}

.fact {
    font-size: 20px;
    line-height: 1.4;
    background-color: #44403c;
    margin-bottom: 16px;
    padding: 16px 24px;
    letter-spacing: -1px;
    border-radius: 16px;
    border: solid 1px #ff8605;
    box-shadow: #2b473c78 0px 14px 28px, #ff86056e 0px 10px 10px;
    display: flex;
    align-items: center;
    gap: 24px;
    min-height: 100%;
}

.facts-list{
    min-height: 1000px;
}
.attributePoints {
    font-size: 10px;
    display: flex;
    border: none;
    background-color: #000;
    font-size: 15px;
    padding: 1px 8px;
    color: inherit;
    font-family: inherit;
    font-weight: 700;
    transition: 0.3s;
    flex-direction: column;
}
.champ {
    font-size: 20px;
    line-height: 1.4;
    background-image: linear-gradient(to right top, rgba(132, 53, 4, 0.89), rgba(62, 14, 12, 0.8));
    margin-bottom: 16px;
    padding: 16px 24px;
    letter-spacing: -1px;
    border-radius: 16px;
    border: solid 1px #ff8605;
    box-shadow: #2b473c78 0px 14px 28px, #ff86056e 0px 10px 10px;
    display: flex;
    align-items: center;
    gap: 24px;
    min-width: 45vw;
    text-shadow: none;
}

@media (max-width: 1300px) {
    .fact {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }
    .champ {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }
    .attributePoints{
        flex-direction: row;
    }
    .imagePlace{
        flex-direction: row !important;
    }
    .imagePlaceTwo {
        flex-direction: row !important;
    }
}
.tag {
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'Julee', cursive;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 100px;
}

.vote-buttons {
  flex-shrink: 0;
  align-items: flex-start;
  display: flex;
  gap: 8px;
}

.vote-buttons button {
  border: none;
  background-color: #000;
  font-size: 18px;
  padding: 6px 12px;
  border-radius: 100px;
  color: inherit;
  font-family: inherit;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
}

.vote-buttons button:hover {
  background-color: #292524;
  box-shadow: #ff8605 0px 20px 30px, #ff8605 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.vote-buttons button:disabled {
  background-color: #44403c;
}

ul {
  list-style: none;
}

.source:link,
.source:visited {
  color: #a8a29e;
  text-decoration: none;
  margin-left: 12px;
  transition: 0.3s;
}

.source:hover,
.source:active {
  color: #3b82f6;
}

.btn {
  border: none;
  font-family: 'Julee', cursive;
  line-height: 1;
  text-transform: uppercase;
  font-size: 1.25rem;
  padding: 10px 0 9px;
  background-image: linear-gradient(135deg, rgb(56 0 1), #640404, #6d1e06, #ec6d10);
  color: inherit;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.3s;
  border: solid 1px #ff8605;
}

.btn:hover {
  transform: scale(110%) rotate(-2deg);
  color: inherit;
  -webkit-text-stroke-width: 1.2px;
  -webkit-text-fill-color: rgb(255 135 7);
  border: solid 4px rgb(255 135 7);
}

.btn-large {
  font-size: 20px;
  padding: 20px 32px 17px;
}

.btn-all-categories {
  margin-bottom: 16px;
  width: 100%;
}

.btn-category {
  width: 100%;
  background-image: none;
}

/* FORM */
.fact-form {
  background-color: #44403c;
  margin-bottom: 40px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  justify-items: center;
  min-width: 300px;
  min-height: 900px;
  justify-content: center;
  text-shadow:none;
}
@media(max-width: 474px){
    .fact-form {
        margin-left: -55px
    }
}
.buildDesc {
    margin-top: -15%;
    background: #000;
    height: 140%;
    width: 100%;
    padding: 1em;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    color: inherit;
    font-size: 15px;
    border: 2px solid #78716c;
}
.buildThumbnail{
    height: 50px;
    width: 50px;

}
.imagePlace {
    display: flex;
    flex-direction: column
}
.imagePlaceTwo {
    display: flex;
    flex-direction: column
}
.armorImages > img{
    font-size: 12px;
    padding-left: -150px;
    width: 30px;
    height: 30px;
}
.armorImages > img:hover{
    transform:scale(7);
    border: 1px solid #ff8605;
}

.auto-grid {
    --auto-grid-min-size: 10rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    grid-gap: 6%;
    margin-top: -13%;
}
@media(max-width:1800px) {
    .auto-grid {
        --auto-grid-min-size: 10rem;
    }
}

@media(max-width:737px) {
    .fact-form {
        min-height: 1100px;
    }
}
@media(max-width:494px) {
    .fact-form {
        min-height: 2500px;
    }
    .auto-grid{
        margin-top: -1600px
    }
}
li {
    padding: 0.1rem 0.1rem;
    text-align: center;
    font-size: 1.2rem;
}

.wordsLeft{
    margin-top: -100px;
    position: relative;
}
.fact-form input,
.fact-form select {
  /*width: 30vw;*/
  text-align: center;
  background-color: #000;
  border: none;
  border-radius: 100px;
  padding: 16px;
  font-size: 12px;
  color: inherit;
  font-family: 'Alegreya Sans SC', sans-serif;
  margin-top: -20vh;
}
.fact-form input::placeholder {
  color: #d3620f;
  font-weight: 700;
}

.fact-form input:first-child {
  flex-grow: 1;
}

.fact-form span {
  font-weight: 600;
  font-size: 14px;
  margin-right: 18px;
}

@media (max-width: 1000px) {
  body {
    /* padding: 32px 48px; */
  }

  .fact-form {
    flex-direction: column;
    align-items: stretch;
  }

  .fact-form input,
  .fact-form select {
    width: auto;
  }
}
